<template>
	<div>Template is not used</div>
</template>

<script>
export default {
	name: 'PuiDocumentPdfViewer',
	data() {
		return {
			validmimetipes: ['image/png', 'image/jpeg', 'application/pdf', 'text/plain', 'text/xml']
		};
	},
	methods: {
		loadDocument() {
			console.log(this.$router.currentRoute);
			const currentRouter = this.$router.currentRoute.path;
			const posDocIndex = currentRouter.lastIndexOf('/showDocument_');
			const data = currentRouter.substring(posDocIndex + 14).split('_');
			const docId = data[0];
			const MimeType = data[1];
			this.selectedMimeType = MimeType;
			// we can close this no mymetypevalid
			if (!this.validmimetipes.includes(this.selectedMimeType)) {
				window.close('', '_parent', '');
			}

			this.getDocument(docId)
				.then((resp) => resp.data.arrayBuffer())
				.then((resp) => {
					const file = new Blob([resp], { type: MimeType });

					const fileURL = URL.createObjectURL(file);
					this.fileURLT = fileURL;
					document.location.href = fileURL;
				});
		},
		getDocument(id) {
			return this.$puiRequests.downloadFileRequest('get', '/puidocument/download', { id });
		}
	},
	mounted() {
		this.loadDocument();
	}
};
</script>
